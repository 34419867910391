import React from 'react'
import { SvgComponent } from 'definitions/Svg'

const LogoTypo: SvgComponent = (props) => (
  <svg
    width="169"
    height="40"
    viewBox="0 0 169 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M62.0296 21.5542C62.0296 23.5826 61.3556 25.2609 60.0221 26.5597C58.6886 27.8585 56.9448 28.5297 54.7908 28.5297C52.8272 28.5297 51.2153 27.9752 49.9697 26.8661C48.7242 25.757 47.9475 24.2977 47.6545 22.459L51.2446 21.7439C51.3765 22.7654 51.7428 23.5826 52.3583 24.2102C52.9737 24.8377 53.7797 25.1441 54.8054 25.1441C55.8312 25.1441 56.6518 24.8085 57.2965 24.1226C57.9413 23.4367 58.249 22.5757 58.249 21.5542V12.9734H52.5194V9.55857H62.0296V21.5542Z"
        fill="white"
      />
      <path
        d="M77.4317 10.5947C78.7652 11.5579 79.6151 12.8567 79.9668 14.4911L76.1861 15.2645C76.0542 14.4035 75.6439 13.7031 74.9699 13.1777C74.2812 12.6523 73.4752 12.3897 72.5227 12.3897C71.6142 12.3897 70.8815 12.6086 70.3247 13.061C69.7678 13.4988 69.4894 14.0533 69.4894 14.6954C69.4894 15.7169 70.1049 16.3444 71.3504 16.5779L75.3509 17.3805C76.8895 17.7162 78.0911 18.3291 78.9557 19.2193C79.8202 20.1095 80.2452 21.2769 80.2452 22.6925C80.2452 24.4874 79.5711 25.903 78.223 26.9537C76.8748 28.0044 75.0871 28.5298 72.8598 28.5298C70.8082 28.5298 69.0498 28.0482 67.5991 27.0996C66.1484 26.1511 65.3131 24.7939 65.0787 23.0135L69.0498 22.2109C69.5334 24.2248 70.9255 25.2317 73.2114 25.2317C74.2665 25.2317 75.0871 25.0128 75.6586 24.5604C76.2301 24.1226 76.5232 23.5535 76.5232 22.8676C76.5232 22.3131 76.362 21.8753 76.0396 21.5688C75.7172 21.2623 75.1604 21.0143 74.3544 20.8537L70.4126 20.1095C67.1448 19.4236 65.5183 17.6578 65.5183 14.7975C65.5183 13.1193 66.1631 11.7622 67.4379 10.7114C68.7128 9.67533 70.4419 9.14997 72.6106 9.14997C74.4863 9.14997 76.0982 9.63155 77.4317 10.5947Z"
        fill="white"
      />
      <path
        d="M111.06 28.1065H107.396V18.008L101.842 28.1065H99.5858L94.0027 18.008V28.1065H90.3393V9.55857H93.6364L100.699 22.3714L107.792 9.55857H111.074V28.1065H111.06Z"
        fill="white"
      />
      <path
        d="M118.565 8.63921C118.99 9.07701 119.21 9.58777 119.21 10.1715C119.21 10.7844 118.99 11.2806 118.565 11.6892C118.14 12.0978 117.612 12.3021 116.982 12.3021C116.338 12.3021 115.795 12.0978 115.385 11.6892C114.975 11.2806 114.755 10.7698 114.755 10.1715C114.755 9.58777 114.975 9.06242 115.4 8.63921C115.825 8.20142 116.352 7.98252 116.982 7.98252C117.612 7.98252 118.14 8.20142 118.565 8.63921ZM115.151 28.1066V14.3014H118.814V28.1066H115.151Z"
        fill="white"
      />
      <path
        d="M134.959 15.498C135.985 16.5779 136.483 17.9789 136.483 19.6863V28.1065H132.82V20.4743C132.82 19.4966 132.571 18.7085 132.058 18.1248C131.545 17.5411 130.871 17.2346 130.021 17.2346C127.72 17.2346 126.577 18.8253 126.577 21.992V28.1065H122.914V14.3014H126.577V15.5418C127.691 14.4327 129.142 13.8782 130.944 13.8782C132.6 13.8782 133.933 14.4181 134.959 15.498Z"
        fill="white"
      />
      <path
        d="M153.966 28.1065H150.303V26.9099C149.13 27.9752 147.606 28.5152 145.716 28.5152C143.884 28.5152 142.287 27.8293 140.939 26.4429C139.591 25.0566 138.917 23.32 138.917 21.2186C138.917 19.1171 139.591 17.3659 140.954 15.9796C142.317 14.5932 143.899 13.8928 145.716 13.8928C147.606 13.8928 149.13 14.4327 150.303 15.498V6.80046H153.966V28.1065ZM149.101 24.1956C149.892 23.4367 150.303 22.4444 150.303 21.2186C150.303 20.0073 149.907 19.0004 149.101 18.2269C148.295 17.4535 147.387 17.0595 146.346 17.0595C145.218 17.0595 144.28 17.4389 143.547 18.1978C142.815 18.9566 142.448 19.9635 142.448 21.2186C142.448 22.4736 142.815 23.4805 143.547 24.2248C144.28 24.969 145.203 25.3484 146.346 25.3484C147.387 25.3484 148.31 24.9544 149.101 24.1956Z"
        fill="white"
      />
      <path
        d="M160.385 23.714C160.546 24.969 161.499 25.5965 163.242 25.5965C163.916 25.5965 164.444 25.4652 164.825 25.2025C165.221 24.9398 165.411 24.6188 165.411 24.2102C165.411 23.5972 164.898 23.1886 163.887 22.9697L161.059 22.386C159.799 22.1233 158.832 21.6563 158.158 20.9705C157.483 20.2846 157.146 19.4528 157.146 18.4458C157.146 17.1033 157.659 15.9942 158.685 15.1478C159.711 14.3014 161.088 13.8782 162.817 13.8782C164.429 13.8782 165.777 14.2576 166.862 15.0164C167.946 15.7753 168.606 16.753 168.855 17.9789L165.352 18.6647C165.279 18.0956 165.001 17.6286 164.532 17.2784C164.063 16.9281 163.447 16.753 162.715 16.753C162.041 16.753 161.572 16.8844 161.264 17.147C160.971 17.4097 160.824 17.7308 160.824 18.0956C160.824 18.6793 161.235 19.0733 162.07 19.2631L165.382 19.9781C166.569 20.2408 167.477 20.7078 168.093 21.4083C168.708 22.0941 169.03 22.926 169.03 23.8891C169.03 25.3776 168.474 26.5305 167.375 27.3185C166.276 28.1065 164.796 28.5151 162.935 28.5151C161.279 28.5151 159.872 28.1795 158.714 27.5082C157.542 26.8369 156.883 25.8154 156.707 24.4436L160.385 23.714Z"
        fill="white"
      />
      <path
        d="M0.0439608 20.2992L0 22.9989C0.131882 27.552 2.03685 31.8278 5.33391 35.0237C8.64562 38.2196 12.9977 39.9854 17.6136 39.9854C27.241 39.9854 35.0661 32.1926 35.0661 22.6049V0H7.34145C3.29706 0 0 3.28347 0 7.3112C0 11.3389 3.29706 14.6224 7.34145 14.6224H17.5843C19.1229 14.6224 20.3832 15.8774 20.3832 17.4097V22.5465C20.3832 24.108 19.1083 25.3776 17.5404 25.3776C15.9578 25.3776 14.6829 24.0934 14.6829 22.5319V20.2992H10.1989V22.4735C10.1989 26.5451 13.5253 29.8577 17.599 29.8577C21.6141 29.8577 24.8672 26.6034 24.8672 22.6195V17.4243C24.8672 13.4258 21.5994 10.1715 17.5843 10.1715H7.34145C5.77352 10.1715 4.484 8.90186 4.484 7.32579C4.484 5.76432 5.75886 4.48012 7.34145 4.48012H30.5821V22.5757C30.5821 29.7264 24.7353 35.5345 17.5697 35.5345H17.4817C10.3161 35.5345 4.49866 29.741 4.49866 22.6049V20.2992H0.0439608Z"
        fill="#F5333F"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="169" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default LogoTypo

import styled from 'styled-components'

const StyledIntro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: ${({ theme }) => theme.gutter.gutter16};
`

const StyledIntroBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 280px;
  width: 100%;
`

const StyledIntroBoxHead = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.gutter.gutter36};
`

const StyledIntroBoxBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
`

export { StyledIntro, StyledIntroBox, StyledIntroBoxHead, StyledIntroBoxBody }

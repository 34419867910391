import React from 'react'
import { Typo1 } from '../../components/Typography/index'

import {
  StyledIntro,
  StyledIntroBox,
  StyledIntroBoxHead,
  StyledIntroBoxBody,
} from './styles'
import LogoTypo from '../../assets/svg/LogoTypo'

interface IProps {
  description: string
}

const Intro: React.FC<IProps> = ({ description }) => (
  <StyledIntro>
    <StyledIntroBox>
      <StyledIntroBoxHead>
        <LogoTypo />
      </StyledIntroBoxHead>
      <StyledIntroBoxBody>
        <Typo1 color="white">{description}</Typo1>
      </StyledIntroBoxBody>
    </StyledIntroBox>
  </StyledIntro>
)

export default Intro

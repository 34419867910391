import React from 'react'

import Layout from '../components/Layout'
import Container from '../components/Container'
import Intro from '../components/Intro/.'

import { HEADER_TYPES } from '../constants/headerTypes'
import { HEADER_BUTTON_TYPES } from '../constants/headerButtonTypes'

const NotFoundPage = () => (
  <Layout
    headerType={HEADER_TYPES.light}
    headerButtonType={HEADER_BUTTON_TYPES.companies}
  >
    <Container bg="tiber">
      <Intro description="Page not found. You just hit a route that doesn&#39;t exist... the sadness." />
    </Container>
  </Layout>
)

export default NotFoundPage
